import React, { useEffect, useState } from "react";
import { t } from "utils/translate";

import { useCart } from "context/cartContext";
import { useCurrency } from "context/currencyContext";
import { useCategories } from "context/categoriesContext";
import { useBrands } from "context/brandsContext";

import { isEmpty } from "utils/helper";

import Button from "components/UI/button";
import ProductPageSlider from 'components/UI/ProductPage/productPageSlider';
import RadioField from "components/UI/Inputs/RadioField";
import SelectField from "components/UI/Inputs/SelectField";
import QuantitySelector from 'components/UI/Inputs/QuantitySelector';

import { GTMAddToCartEvent, GTMViewItemEvent } from "components/GTM/gtmProduct";

import "styles/product-page.scss";

const ProductPageBanner = (props) => {
    const { product, parentCategory } = props;
    const [showSubscription, setShowSubscription] = useState(true);

    const handlePurchaseType = (value) => {
        setShowSubscription(value === 'subscription');
    };

    const { increaseQty, findCartProduct, isSubscriptionAdded, openCart } = useCart();
    const { currency } = useCurrency();
    const { getCategoryName } = useCategories();
    const { getBrandName } = useBrands();

    const getGTMData = (discount = 0) => ({
        currency: currency.currency_code,
        value: product.price,
        items: [
            {
                item_id: product.bigcommerce_id,
                item_name: product.name,
                discount: discount.toFixed(2),
                item_brand: getBrandName(product.brand_id),
                item_category: getCategoryName(product.categories[0]),
                item_variant: product.variants[0].id,
                price: (product.price - discount).toFixed(2),
            },
        ],
    });

    useEffect(() => {
        if (currency?.name) {
            const discount = +(
                product.variants[0].price ? (
                    product.price -
                    product.variants[0].price /
                    (parseInt(product.variants[0].option_values[0].label.split("every")[0], 10) || 1)
                ) : (
                    0
                )
            );
            GTMViewItemEvent(getGTMData(discount));
        }
    }, [currency]);

    const addToCart = (event) => {
        event.preventDefault()

        const data = new FormData(event.target);
        const productVariant = Object.fromEntries(data.entries());

        const selectedVariant =
            product.variants
                .map((variant) => {
                    const variantOptions = {}
                    variant.option_values
                        .forEach(({ option_display_name, label }) => {
                            variantOptions[option_display_name] = label
                        })

                    return {
                        ...variant,
                        ...variantOptions
                    }
                })
                .find((variant) => {
                    let result = true
                    Object.keys(productVariant)
                        .filter((key) => key !== 'PurchaseType' && key !== 'quantity')
                        .forEach((key) => {
                            result = result && variant[key] === productVariant[key]
                        })
                    return result
                })

        if (selectedVariant.Subscription !== 'eenmalige aankoop' && isSubscriptionAdded(selectedVariant)) {
            openCart()
            return null
        }

        if (product.variants.length > 1 && !selectedVariant) {
            // toast.error('Select a variant');
            return null
        }

        const cartProduct = findCartProduct({
            variant_id: selectedVariant.id,
            bigcommerce_id: product.bigcommerce_id
        });
        const quantity = (+productVariant.quantity || 1)

        increaseQty({
            ...product,
            variant_id: selectedVariant?.id,
            quantity: cartProduct?.quantity || 0,
            brand: getBrandName(product.brand_id),
            category: getCategoryName(product.categories[0])
        }, quantity);

        const subscriptionQuantity = parseInt(selectedVariant.Subscription.split("every")[0], 10);
        const eventQuantity = subscriptionQuantity || quantity
        const eventValue = isEmpty(subscriptionQuantity) ?
            product.price * eventQuantity :
            (selectedVariant.price ?? product.price * eventQuantity)
        const eventPrice = eventValue / eventQuantity
        GTMAddToCartEvent({
            currency: currency.currency_code,
            value: eventValue,
            items: [{
                ...getGTMData().items[0],
                quantity: eventQuantity,
                discount: product.price - eventPrice,
                item_variant: selectedVariant.id,
                price: eventPrice,
            }]
        });
    };

    const dosageVariants = [];
    const subscriptionVariants = [];
    const productVariantsPrice = [];

    product.variants.forEach((variant) => {
        const dosageOption = variant.option_values.find((option) => option.option_display_name === 'Dosage');
        const subscriptionOption = variant.option_values.find((option) => option.option_display_name === 'Subscription');

        if (dosageOption && !dosageVariants.includes(dosageOption.label)) {
            dosageVariants.push(dosageOption.label);
        }

        if (subscriptionOption && !subscriptionVariants.includes(subscriptionOption.label) &&
            subscriptionOption.label !== 'eenmalige aankoop') {
            subscriptionVariants.push(subscriptionOption.label);
            productVariantsPrice.push(variant.price);
        }
    });

    const isOneTimePurchase = product.custom_fields.find(field => field.name === "One-time-purchase" && field.value === "true");
    const isSubscriptionPurchase = product.custom_fields.find(field => field.name === "Subscription" && field.value === "true");
    const isPrescription = product.custom_fields.find(field => field.name === "Prescription" && field.value === "true");
    const isQuestionnaire = product.custom_fields.find(field => field.name === "Questionnaire" && field.value === "true");
    const Substance = product.custom_fields.find(field => field.name === "Substance" && field.value === "true");

    let discount = (product.price - (Math.max(...productVariantsPrice) / [parseInt(subscriptionVariants[subscriptionVariants.length - 1], 10)])) / product.price * 100;

    if (discount > 0) {
        discount = `${t("up to")} ${discount.toFixed(0)}%`;
    } else {
        discount = null;
    }

    const productDescription = product.description.split('<p><!-- pagebreak --></p>');

    return (
        <div className="product-page__banners">
            <div className="product-page__banner">
                <ProductPageSlider slides={product.images}/>
            </div>

            <form className="product-page__list" onSubmit={addToCart}>
                <h2 className="typography__h1">{product.name}</h2>
                {
                    Substance &&
                    <h3 className="typography__small--inter product-page__subtext">
                        {Substance}
                    </h3>
                }
                <p
                    className="product-page__list-description typography__p--inter"
                    dangerouslySetInnerHTML={{ __html: productDescription[0] }}
                />

                {(!isPrescription && !isQuestionnaire) && (
                    <div className="">
                        <p className="typography__p product-page__frequency">Frequentie</p>
                        <div className="product-page__purchase-type">
                            {isSubscriptionPurchase && (
                                <RadioField
                                    className={'product-page__radio'}
                                    name={'PurchaseType'}
                                    value={t("Subscribe and save")}
                                    children={discount}
                                    required
                                    onChange={() => {
                                        handlePurchaseType("subscription")
                                    }}
                                    defaultChecked
                                />
                            )}
                            {isOneTimePurchase && (
                                <RadioField
                                    className={'product-page__radio'}
                                    name={'PurchaseType'}
                                    value={t("One-time purchase")}
                                    children={`${currency.token}${product.price}`}
                                    required
                                    onChange={() => {
                                        handlePurchaseType("eenmalige aankoop")
                                    }}
                                />
                            )}
                        </div>

                        {product.variants.length > 0 && (
                            <div className="product-page__variants">

                                {dosageVariants.length > 0 && (
                                    <div className="product-page__subscription">
                                        <p className="typography__p">Dosage</p>
                                        <div className="product-page__dosage">
                                            {dosageVariants.map((variant, index) => {
                                                return (
                                                    <RadioField
                                                        key={variant}
                                                        className={'form__radio'}
                                                        name={'Dosage'}
                                                        value={variant}
                                                        required
                                                        checked={dosageVariants.length === 1 && index === 0}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                                {subscriptionVariants.length > 0 && (
                                    showSubscription ? (
                                        <div className="product-page__subscription">
                                            <p className="typography__p">
                                                {t("Select your plan")}
                                            </p>
                                            <p className="typography__small--inter">
                                                {t("Op elk moment opzegbaar")}
                                            </p>
                                            <SelectField
                                                options={subscriptionVariants}
                                                prices={productVariantsPrice}
                                                price={product.price}
                                            />
                                        </div>
                                    ) : (
                                        <input name="Subscription" value="eenmalige aankoop" type="hidden"/>
                                    )
                                )}
                            </div>
                        )}

                        {!showSubscription && isOneTimePurchase && (
                            <div className="product-page__subscription">
                                <p className="typography__p">Aantal</p>
                                <QuantitySelector/>
                            </div>
                        )}
                    </div>
                )}
                <div className="product-page__button">
                    {(isPrescription || isQuestionnaire) ? (
                        <Button
                            value="Start vandaag"
                            type="dark"
                            isArrowShow
                            href={({
                                "HAIR_LOST": "/questionnaire-hair",
                                "LAST_LONGER": "/questionnaire-premature-ejaculation",
                                "HAVE_BETTER_SEX": "/questionnaire-erectile-dysfunction"
                            }[parentCategory])}
                        />
                    ) : (
                        <Button
                            value="In winkelmand toevoegen"
                            type="dark"
                            isSubmit
                        />
                    )}
                </div>
                <div
                    className="product-page__list-items typography__p"
                    dangerouslySetInnerHTML={{ __html: productDescription[1] }}
                />
            </form>
        </div>
    );
};

export default ProductPageBanner;
