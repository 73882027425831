import React from "react";
import { Link } from "gatsby";
import Slider from "./slider"
import Button from "./button";
import { GTMSelectContentEvent } from "../GTM/gtmStaticPage";

import Image from "components/UI/image";

import "styles/blog-slider.scss";

import BannerArrowIcon from "images/svg/bannerArrow.svg";

const BlogSlider = (props) => {
    const { sliderData, isHomepage } = props;

    const homepageSliderConfig = {
        spaceBetween: 16,
        slidesPerView: 1.2,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        breakpoints: {
            412: {
                slidesPerView: 1.2,
            },
            768: {
                slidesPerView: 2.2,
                spaceBetween: 28,
            },
            1024: {
                slidesPerView: 3.2,
                spaceBetween: 28,
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 28,
            }
        },
        className: "swiper-container product-page__slider-dots",
    };

    const pdpSliderConfig = {
        spaceBetween: 16,
        slidesPerView: 1.2,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        breakpoints: {
            412: {
                slidesPerView: 1.2,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 28,
            }
        },
        className: "swiper-container product-page__slider-dots",
    };

    if (!sliderData) {
        return;
    }

    const onClick = (title) => {
        GTMSelectContentEvent({
            content_type: title,
            content_id: title.toLowerCase().replaceAll(' ', '-')
        });
    }

    return (
        <div className="blog-slider">
            <div className="blog-slider__title-container">
                <h3 className="blog-slider__title typography__h3">
                    {sliderData.sliderTitle}
                </h3>
                <div className="blog-slider__all-button">
                    <Button
                        className="blog-slider__content-button"
                        value={sliderData.sliderButton?.text}
                        type={sliderData.sliderButton?.type}
                        href={sliderData.sliderButton?.href}
                        isArrowShow={sliderData.sliderButton?.isArrowShow}
                        isThin={sliderData.sliderButton?.isThin}
                    />
                </div>
            </div>
            <div className="blog-slider__slides">
                <Slider config={isHomepage ? homepageSliderConfig : pdpSliderConfig}>
                    {sliderData.slides.map((slide, key) => (
                        <div className="blog-slide" key={key}>
                            <div className="blog-slide__image-container">
                                {(slide.slideImage || slide.imageMobile) && (
                                    <div className='blog-slide__image-container'>
                                        <Image image={slide.slideImage} imageMobile={slide.imageMobile}
                                               className="blog-slide__picture"/>
                                    </div>
                                )}
                            </div>
                            <Link className="blog-slide__title-container"
                                  onClick={() => onClick(slide.title)}
                                  to={slide.slideButton?.href}>
                                <p className="blog-slide__title typography__title">{slide.title}</p>
                                <div className="blog-slide__action-container">
                                    <Image src={BannerArrowIcon} alt="Arrow"/>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="blog-slider__mobile-banners-link">
                <Button
                    className="treatments-slide__content-button"
                    value={sliderData.sliderButton?.text}
                    type={sliderData.sliderButton?.type}
                    href={sliderData.sliderButton?.href}
                    isArrowShow={sliderData.sliderButton?.isArrowShow}
                    isThin={sliderData.sliderButton?.isThin}
                />
            </div>
        </div>
    );
};

export default BlogSlider;
