import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Button from "../button";
import ProductPageFAQ from "./ProductPageFAQ";
import ProductPageDotsSlider from "./productPageDotsSlider";
import RoundImageBanner from "../roundImageBanner";
import ProductSlider from "../productSlider";
import Dropdown from "../dropdown";
import ProductPageDoctorBanner from "./productPageDoctorBanner";
import ProductPageReviews from "./productPageReviews";
import BlogSlider from "../blogSlider";
import Image from "components/UI/image";

import "styles/homepage.scss";
import "styles/product-page.scss";

export const ProductPageContentQuery = graphql`
  query DropdownProductBannerQuery {
    allContentfulBannerUpdated {
      edges {
        node {
          title
          text {
            raw
          }
          plainText
          type
          location
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          imageMobile {
            file {
              url
            }
            title
          }
          bulletListText {
            id
            dropdownTitle
            text
            richText {
              raw
            }
          }
          link {
            href
            text
            type
            isArrowShow
          }
        }
      }
    }
    allContentfulSlider {
      edges {
        node {
          name
          type
          location
          sliderTitle
          slideCount
          dots
          sliderButton {
            text
            type
            href
            isThin
            isArrowShow
          }
          slides {
            id
            title
            subtext
            slideImage {
              title
              file {
                url
                contentType
              }
              gatsbyImageData(
                placeholder: NONE,
                formats: [AUTO, WEBP, AVIF],
              )
            }
            imageMobile {
              title
              file {
                url
                contentType
              }
              gatsbyImageData(
                placeholder: NONE,
                formats: [AUTO, WEBP, AVIF],
              )
            }
            slideButton {
              href
              text
              type
              isArrowShow
              isThin
            }
          }
        }
      }
    }
    allContentfulContentfulPage {
      nodes {
        name
        bannerItems {
          type
          title
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          imageMobile {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
        }
      }
    }
  }
`;

const ProductPageContent = (props) => {
    const { product, products, parentCategory } = props;

    const relatedProducts = product.related_products
        .map((relatedProductId) => {
            return products.find(
                (product) => product.bigcommerce_id === relatedProductId
            );
        })
        .filter((relatedProduct) => relatedProduct != null)

    const {
        allContentfulSlider,
        allContentfulBannerUpdated,
        allContentfulContentfulPage
    } = useStaticQuery(ProductPageContentQuery);

    const productSliderData = allContentfulSlider.edges;
    const banners = allContentfulBannerUpdated.edges;
    const pages = allContentfulContentfulPage.nodes;

    const [roundImageBanner] = productSliderData.filter(
        (slider) =>
            slider.node.type === "product" &&
            slider.node.location === "homepage"
    );

    const [doctorBannerData] = productSliderData.filter(
        (slider) =>
            slider.node.type === "doctors-banner" &&
            slider.node.location === `product-page ${product.name}`
    );

    const [dotsSliderData] = productSliderData.filter(
        (slider) =>
            slider.node.type === "product-page-dots-slider" &&
            slider.node.location === `product-page ${product.name}`
    );

    const reviewsType = product.custom_fields.find(
        (el) => el.name === "reviews_type"
    );

    const [reviewsSliderData] = productSliderData.filter(
        (slider) =>
            slider.node.type === reviewsType?.value &&
            slider.node.location === "product-page"
    );

    const [blogSliderData] = productSliderData.filter(
        (slider) =>
            slider.node.type === "blog-slider" &&
            slider.node.location === `product-page ${product.name}`
    );

    const [dropdownBanner] = banners.filter(
        (banner) =>
            banner.node.location === `productpage ${product.name}` &&
            banner.node.type === "dropdown"
    );

    const [howItWorksData] = banners.filter(
        (banner) =>
            banner.node.location === `product-page ${product.name}` &&
            banner.node.type === "how-it-works"
    );

    const faqType = product.custom_fields.find(
        (el) => el.name === "faq_type"
    );

    const [FAQBannerData] = banners.filter(
        (banner) =>
            banner.node.location === "product-page" &&
            banner.node.type === faqType?.value
    );

    const [DoctorHelpBannerData] = banners.filter(
        (banner) =>
            banner.node.location === `product-page` &&
            banner.node.type === "not-sure-banner"
    );

    const [leftRightBlockData] = pages.filter(
        (page) =>
            page.name === `Product-page left-right-block ${product.name}`
    );

    let doctorsHelpLink = "#";
    if (parentCategory === "HAIR_LOST")
        doctorsHelpLink = "/questionnaire-hair";
    else if (parentCategory === "HAVE_BETTER_SEX")
        doctorsHelpLink = "/questionnaire-premature-ejaculation";
    else if (parentCategory === "LAST_LONGER")
        doctorsHelpLink = "/questionnaire-erectile-dysfunction";

    const [doctorHelpBannerTitle, setDoctorHelpBannerTitle] = useState(DoctorHelpBannerData?.node.title.replace("{product-name}", product?.name));

    useEffect(() => {
        if(product?.name) {
            setDoctorHelpBannerTitle(DoctorHelpBannerData.node.title.replace("{product-name}", product.name))
        }
    }, [product, setDoctorHelpBannerTitle, DoctorHelpBannerData]);

    return (
        <div className={`homepage__content ${leftRightBlockData && 'no-padding'}`}>
            {dropdownBanner && (
                <div className="homepage__dropdown-banner product-page__dropdown-banner">
                    <div className="product-page__dropdown-text">
                        <p className="typography__h2 product-page__dropdown-title">
                            {dropdownBanner?.node.title}
                        </p>
                        {dropdownBanner?.node.text &&
                            <p className="page-content__text typography__subtitle">
                                {renderRichText(dropdownBanner?.node.text)}
                            </p>
                        }
                    </div>
                    <div className="homepage__dropdown-banner__content">
                        <Dropdown items={dropdownBanner?.node.bulletListText}/>
                    </div>
                </div>
            )}

            {dotsSliderData && (
                <div className="product-page__dots-slider">
                    <p className="typography__h2 product-page__dots-slider-title">{dotsSliderData?.node.sliderTitle}</p>
                    <ProductPageDotsSlider slides={dotsSliderData?.node}/>
                </div>
            )}

            {howItWorksData && (
                <div className="page-content__banner">
                    <Image image={howItWorksData?.node.image} alt='Banner image'/>
                    <div className="product-page__banner-wrapper">
                        <p className="typography__subtitle">{howItWorksData?.node.title}</p>
                        <p className="product-page__banner-wrapper__text">
                            {howItWorksData?.node.text ? (
                                renderRichText(howItWorksData?.node.text)
                            ) : (
                                howItWorksData?.node.plainText
                            )}
                        </p>
                    </div>
                    <Button
                        className='product-page__banner-button'
                        value={howItWorksData?.node.link.text}
                        type={howItWorksData?.node.link.type}
                        isArrowShow={howItWorksData?.node.link.isArrowShow}
                        href={howItWorksData?.node.link.href}
                    />
                </div>
            )}

            {leftRightBlockData && (
                <div className="product-page__about-us-banner">
                    {leftRightBlockData?.bannerItems?.map((item, index) => (
                        <div key={index}>
                            <div key={item.id} className={`about-us__banner ${item.type}`}>
                                <div className="about-us__image-container">
                                    <Image image={item.image} imageMobile={item.imageMobile} className="about-us__image"/>
                                </div>
                                <div className="about-us__title-container">
                                    <p className="about-us__title">{item.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {reviewsSliderData && (
                <ProductPageReviews sliderData={reviewsSliderData.node}/>
            )}

            {doctorBannerData && (
                <ProductPageDoctorBanner elements={doctorBannerData.node.slides}/>
            )}

            {DoctorHelpBannerData && product.name !== "Thickening Shampoo" && product.name !== "Growth Gummies" && product.name !== "Delay Wipes" && (
                <div className="doctor-help">
                    <h2 className="typography__h2">
                        {doctorHelpBannerTitle}
                    </h2>
                    <p className="typography__p--inter">
                        {DoctorHelpBannerData.node.plainText}
                    </p>
                    <Button
                        className='doctor-help__button'
                        value={DoctorHelpBannerData.node.link.text}
                        type={DoctorHelpBannerData.node.link.type}
                        isArrowShow={DoctorHelpBannerData.node.link.isArrowShow}
                        href={doctorsHelpLink}
                    />
                </div>
            )}

            <RoundImageBanner bannerData={roundImageBanner.node}/>

            {faqType && (
                <ProductPageFAQ banner={FAQBannerData.node}/>
            )}
            {blogSliderData && (
                <BlogSlider sliderData={blogSliderData.node}/>
            )}

            {relatedProducts.length > 0 && (
                <div className="related-products">
                    <ProductSlider
                        title="Bekijk ook"
                        isHomepage
                        products={relatedProducts}
                    />
                </div>
            )}
        </div>
    );
};

export default ProductPageContent;
