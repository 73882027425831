import React from "react";

import Image from "components/UI/image";
import Slider from "components/UI/slider";

const ProductPageDotsSlider = ({ slides }) => {
    const sliderConfig = {
        slidesPerView: 1.15,
        slidesPerGroup: 1,
        spaceBetween: 20,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        navigation: {
            nextEl: ".button-next-slide",
            prevEl: ".button-prev-slide",
        },
        breakpoints: {
            425: {
                slidesPerView: 1.25,
                spaceBetween: 10,
            },
            500: {
                spaceBetween: 20,
            },
            540: {
                slidesPerView: 1.5,
            },
            670: {
                slidesPerView: 1.75,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 1,
            },
            940: {
                slidesPerView: 2.5,
                spaceBetween: 20,
                slidesPerGroup: 1,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1300: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
        className: "swiper-container product-page__slider-dots",
    };

    return (
        <Slider config={sliderConfig}>
            {Object.values(slides.slides).map((slide, index) => (
                <div className="slide-container" key={index}>
                    <Image image={slide.slideImage} className="slide-image"/>
                    <div className="slide-content">
                        <p className="slide-title typography__subtitle">{slide.title}</p>
                        <p className="slide-text">{slide.subtext}</p>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default ProductPageDotsSlider;
