import React from 'react';

import Image from "components/UI/image";

const ProductPageDoctorBanner = (props) => {
    const { elements } = props;

    return (
        <div className="product-page__doctor-banner">
            <div className='product-page__doctor-background'>
                <div className="product-page__doctor-content">
                    <h2 className="typography__h2">
                        {elements[0].title}
                    </h2>
                    <div className="product-page__doctor-wrapper">
                        <div className="product-page__doctor-left">
                            <p className="product-page__doctor-text">
                                {elements[0].subtext}
                            </p>
                            <div className="product-page__doctor-info">
                                <Image image={elements[1].slideImage} className="product-page__doctor-img"/>
                                <div className="product-page__doctor-info-text">
                                    <p className="typography__p">
                                        {elements[1].title}
                                    </p>
                                    <p className="typography__small--inter">
                                        {elements[1].subtext}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="product-page__doctor-questions">
                            {elements.slice(2).map((element, index) => (
                                <div key={index} className="product-page__doctor-question">
                                    <p className="typography__p">{element.title}</p>
                                    <p className="typography__p--inter">{element.subtext}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductPageDoctorBanner;
