import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/bundle";

import Image from "components/UI/image";

import "styles/product-page.scss";

const ProductPageSlider = ({ slides }) => {
    const pagination = {
        clickable: true,
        bulletClass: "swiper-pagination-image",
        bulletActiveClass: "swiper-pagination-image-active",
        renderBullet: (index, className) => renderToStaticMarkup(
            <span className={className}>
                <Image src={slides[index]?.url_tiny} alt={`Slide ${index + 1}`}/>
            </span>
        )
    };

    const findThumbnailImgIndex = slides.findIndex(slide => {
        return slide.is_thumbnail === true
    });
    //Make Thumbnail image first in swiper array
    if (findThumbnailImgIndex !== -1) {
        slides.splice(0, 0, slides.splice(findThumbnailImgIndex, 1)[0])
    }

    return (
        <Swiper
            pagination={pagination}
            modules={[Pagination]}
            slidesPerView={1}
            spaceBetween={50}
        >
            {Object.values(slides).map((slide, index) => (
                <SwiperSlide key={index}>
                    <Image src={slide.url_zoom} alt={`Slide ${index + 1}`}/>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ProductPageSlider;
