import React from "react";

import Main from "components/Layout/main";
import ProductPageBanner from "components/UI/ProductPage/productPageBanner";
import ProductPageContent from "components/UI/ProductPage/productPageContent";
import HtmlHead from "components/UI/htmlHead";

import "styles/product-page.scss";

const ProductPage = (props) => {
    const { product, products, categoryName, allContentfulPromoBanner } = props.pageContext;

    const parentCategory = product?.custom_fields.find(item => item.name === "parent_category")?.value;

    const currentPromoBanner = allContentfulPromoBanner?.nodes
        .find(el =>
                el.location === (
                    parentCategory === "LAST_LONGER" ?
                        "Langer uithouden" :
                        categoryName
                )
        );

    const getStyle = () => {
        let backgroundColor;
        switch (parentCategory) {
            case "HAIR_LOST":
                backgroundColor = '#DDE4E5';
                break;
            case "LAST_LONGER":
                backgroundColor = '#F4EEDA';
                break;
            case "HAVE_BETTER_SEX":
                backgroundColor = '#F4EEDA';
                break;
            default:
                backgroundColor = '#DDE4E5';
                break;
        }

        return backgroundColor ?
            `:root { --background-main: ${backgroundColor};}` :
            '';
    }

    const gtmData = {
        page: {
            title: product.name,
            type: "Product",
        },
    };

    const meta = [];

    if (product.meta_description) {
        meta.push({
            name: `description`,
            content: product.meta_description,
        });
    }

    return (
        <>
            <HtmlHead title={product.name} meta={meta} style={getStyle()}/>
            <Main promoBannerData={currentPromoBanner} className="product-page__main" gtmData={gtmData}>
                <ProductPageBanner product={product}  parentCategory={parentCategory}/>
                <ProductPageContent product={product} products={products} parentCategory={parentCategory}/>
            </Main>
        </>
    );
};

export default ProductPage;
