import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useWindow } from "context/windowContext";
import { useCurrency } from "context/currencyContext";

import ArrowIcon from "images/svg/dropdownArrow.svg";

import Image from "components/UI/image";

const SelectField = ({ options, prices, price, className }) => {
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [selectedPrice, setSelectedPrice] = useState(prices[0]);
    const [visible, setVisible] = useState(false);

    const { document } = useWindow();
    const { currency } = useCurrency();

    const handleDropdownRef = useRef();

    const handleDropdownClick = () => {
        setVisible(prevVisible => !prevVisible);
    };

    const handleOptionSelect = (option, price) => {
        setSelectedOption(option);
        setSelectedPrice(price);
        handleDropdownRef.current.blur();
    };

    const handleClickOutside = useCallback(
        (e) => {
            if (
                handleDropdownRef.current &&
                visible &&
                !handleDropdownRef.current.contains(e.target)
            ) {
                setVisible(false);
            }
        },
        [visible]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        if (!visible) {
            handleDropdownRef.current.blur();
        }
    }, [handleClickOutside]);

    return (
        <div className={`container ${className}`}>
            <div className="select-field typography__small" tabIndex="0" onClick={handleDropdownClick}
                 ref={handleDropdownRef}>
                <div className="select-field__title">
                    <span>
                        {selectedOption}
                    </span>
                    <div className='select-field__right'>
                        <div className="select-field__price">
                            {
                                price !== +(selectedPrice / parseInt(selectedOption, 10)).toFixed(0) && price !== (selectedPrice / parseInt(selectedOption, 10)).toFixed(0) &&
                                <p className="select-field__default-price">
                                    {currency.token}{price}
                                </p>
                            }
                            <p>
                                {currency.token}{(selectedPrice / parseInt(selectedOption, 10)).toFixed(0)}/ea
                            </p>
                        </div>
                        <Image
                            className={`select-field__icon ${visible ? "select-field__icon--active" : ""}`}
                            src={ArrowIcon}
                        />
                    </div>
                </div>
                <input type="hidden" name="Subscription" value={selectedOption}/>
                {visible && (
                    <ul className="select-field__dropdown">
                        {options.map((option, index) => (
                            <li 
                                key={index} 
                                id={option} 
                                onClick={() => handleOptionSelect(option, prices[index])}
                                className={option === selectedOption ? "select-field__dropdown--selected" : ""}    
                            >
                                <span>
                                    {option}
                                </span>
                                <div className="select-field__price">
                                    {
                                        price !== +(prices[index] / parseInt(option, 10)).toFixed(0) && price !== (prices[index] / parseInt(option, 10)).toFixed(0) &&
                                        <p className="select-field__default-price">
                                            {currency.token}{price}
                                        </p>
                                    }
                                    <p>
                                        {currency.token}{(prices[index] / parseInt(option, 10)).toFixed(0)}/ea
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SelectField;
